.project-container{

}
.header {
    font-size: 2.5rem;
    padding-top: 5rem;
    padding-bottom: 5rem;
}

.portfolio_container {
    font-size: 3rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2.5rem;
    padding: 2.5rem;
    position: relative;
}

.portfolio_item {
    background-color: rgba(0, 108, 250, 0.685);
    padding: 1.3rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: all 1s cubic-bezier(0.215, 0.610, 0.355, 1)
}

.portfolio_item:hover {
    background-color: gainsboro;
}

.portfolio_item-image {
    border-radius: 1.5rem;
    overflow: hidden
}

.portfolio_item-image img {
    border-radius: 1.5rem;
    overflow: hidden
}

.portfolio_item h3 {
    margin: 1.2rem 0 2rem;
}

.portfolio_item-cta {
    display: flex;
    justify-content: flex-start;
    gap: 1rem;
    margin-bottom: 1rem;
}

.portfolio_item-cta a{
    transition: all 2s cubic-bezier(0.075, 0.82, 0.165, 1)
}
.portfolio_item-cta a:hover {
    color: rgba(0, 108, 250, 0.685);
}
.portfolio_item_text{
    font-size: 2rem;
}
@media screen and (max-width:1024px) {

    .portfolio_container {
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }
}

@media screen and (max-width:600px) {

    .portfolio_container {
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
    }
}