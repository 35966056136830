*{
    box-sizing: border-box;
}

body{
    margin: 0;
}
.name{
    position: absolute;
    transform: translateX(-300%);
    transition: all 0.35s cubic-bezier(0.215, 0.610, 0.355, 1);
}
.navbar {
    background-color: rgb(134, 197, 207);
    color: rgb(0, 0, 0);
    display: flex;
    justify-content: center;
    align-items: stretch;
    gap: 5rem;
    padding: 2rem;
    font-size: 2.5rem;
    font-family: 'Playfair Display', serif;
}
.navbar a{
    color: inherit;
    text-decoration: none;
    transition: all 0.35s cubic-bezier(0.215, 0.610, 0.355, 1);
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0.25rem;
}

.navbar ul{
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    gap: 8rem;
}
.navbar a.active{
    color: rgb(0, 108, 250);
}
.navbar li:hover{
    color: rgba(0, 108, 250, 0.377);
}
.name a:hover{
    color: rgba(0, 108, 250, 0.377);
}
.navbar_links {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}