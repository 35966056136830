.project-heading{
    text-align: center;
    padding: 4rem 0 2rem 0 ;
}
.project-container{
    max-width: 1140px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 40px;
    padding-bottom: 5rem;
}
.project-card{
    background-color: black;
    padding: 1.2rem 1rem;
}
.project-card img{
    width: 100%;

}