@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');

body {
    font-family: 'Playfair Display', serif;
}

.header_container h5 {
    animation: 1s 1 alternate slideinfade;
    font-size: 3.5rem;
}

@keyframes slideinfade {
    from {
        opacity: 0;
        margin-left: 100%;
        width: 200%;
    }

    50% {
        opacity: 0.5;
    }

    75% {

        margin-left: 0%;
        width: 100%;
    }

    to {
        opacity: 1;
        margin-left: 0%;
        width: 100%;
    }
}

.contact_links {
    padding: 1rem;
    animation: 2s 1 alternate slideinfade;

}

.contact_links a {
    padding-top: 0.5rem;
    padding: 2.75rem;

    Transition: all 0.35s linear;
}

.contact_links a:hover {
    color: rgba(0, 108, 250, 0.377);
}

.contact_icons {
    font-size: 4rem;
}
.aboutMe{
    width: 100%;
    height: 100%;
}
.aboutMe h2 {
    animation: 4s 1 fade;
    padding: 2.5rem 10rem;
    line-height: 1.5;
    text-align: left;
    display: block;
    margin-block-start: 1rem;
    margin-block-end: 1rem;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

@keyframes fade {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
.skillList{
    columns: 5rem 3;
}
.skillList ul {
    list-style-type: square;
    padding: 1rem
}
.skillList li {
    padding-left: 10rem;
    padding-right: 5rem;
    font-size: 1.5rem;
    list-style-type: disc;
    list-style-position: inside;
    text-align: left;
}
/* html{
    font-size: 1vw;
} */

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media screen and (min-width:1080px) {

    html{
        font-size: 16px;
    }
  
  }
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    html{
        font-size: 14px;
    }
  
  }
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

    html{
        font-size: 10px;
    }

  }
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {

    html{
        font-size: 6.5px;
    }
    .skillList{
        columns: 5rem 2;
    }
  }
  