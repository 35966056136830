@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');


* {
  font-family: 'Playfair Display', serif;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  text-align: center;
}

a {
  color: unset;
  text-decoration: none;
}
.page-container{
  position: relative;
  min-height: 100vh;
}