@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');

* {
    font-family: 'Playfair Display', serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;

}

.contact {
    position: relative;
    padding: 50px 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}

.contact .content h1 {
    font-size: 4rem;
    padding: 2rem;
}

.contact .content h3 {
    font-size: 2rem;
}

.contact .content p {
    font-size: 2rem;
    font-weight: 500;
    padding-bottom: 1.5rem;
}

.container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.5rem;
}

.contact .box .text {
    padding-right: 1rem;
}


form {
    width: 80%;
    max-width: 37.5rem;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
}

label {
    font-size: 1.5rem;
    width: 100%;
    margin-bottom: 1rem;
    position: relative;
    text-align: left;
    transition: all 0.2s ease-in-out;
}

label:hover {
    transform: scale(1.05);
}

input:focus+label,
textarea:focus+label {
    transform: scale(1.1);
    color: #4CAF50;
}

input,
textarea {
    width: 100%;
    padding: 1.5rem 2rem;
    margin: .8rem 0;
    box-sizing: border-box;
    border: .2rem solid #ccc;
    border-radius: .4rem;
    transition: all 0.2s ease-in-out;
}

input:focus,
textarea:focus {
    border: .2rem solid #4CAF50;
    outline: none;
}

.submitBtn {
    width: 100%;
    background-color: #686868;
    color: white;
    padding: 1.8rem 2rem;
    margin: .8rem 0;
    border: none;
    border-radius: .4rem;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    margin-bottom: 5rem;
}

.submitBtn:hover {
    background-color: #45a049;
    transform: scale(1.05);
}